import React, { Component } from 'react';
import api from '../../services/api';


export default class Comercial extends Component {

    state = { _vendas: [], }

    async componentDidMount() {
        await this.getVendas();
    }

    async getVendas() {
        const response = await api.get('/comercial');
        this.setState({ _vendas: response.data });
    }


    render() {
        return (
            <div classname="container-japa">
                <iframe
                    title="FrameJapa"
                    className="iframe-japa"
                    srcdoc={this.state._vendas}
                    allowFullScreen
                >
                    Seu Navegador não aceita esse recurso!
         </iframe>
            </div>
        )
    }
}
