import React, {useState, createContext} from 'react'

export const UserContext = createContext();

const UserProvider = ({children}) => {

    const [data, setData] = useState({
        logger : !!localStorage.getItem('usenet-token')
    })

    const isLogger = data.logger

    const alterLogin = value => {
        setData({...data, logger : value})
    }
    return (       
        <UserContext.Provider value={{isLogger, alterLogin}}>
            {children}
        </UserContext.Provider>
    )   
}
export default UserProvider;