import React from 'react';
import './App.css';
import MenuNavegacao from './componentes/menu/index';
import {Router} from 'react-router-dom';
import Routes from './routes';
import UserProvider from './context';
import {history} from '../src/history';

function App() {
  return (
    <UserProvider>
      <div className="App">
        <div className="Container-fluid">

          <Router history={history}>
            <div className="Container-fluid">
              <MenuNavegacao />
            </div>
            <main role="main" className="col-md-12 col-lg-10">
              <div className="float-left">
                <Routes />
              </div>
            </main>
          </Router>
        </div>
      </div>

    </UserProvider>
  );
}

export default App;
