import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context';
import { history } from '../../history';

const MenuNavegacao = () => {
    const context = useContext(UserContext);
    const logout = () => {
        localStorage.removeItem('usenet-token');
        context.alterLogin(false);
        history.push('/login');
    }
    if (context.isLogger) {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <spam className="navbar-brand">Reality
                </spam>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Alterna navegação">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <div className="float-left">
                                <Link to={'/'} className=
                                    "nav-link">Home
                            </Link>
                            </div>
                        </li>
                        <li className="nav-item active">
                            <div className="float-left">
                                <Link to={'/comercial'} className="nav-link">Vendas
                            </Link>
                            </div>
                        </li>
                        <li className="nav-item active">
                            <div className="float-left">
                                <Link to={'/gerencialvendas'} className="nav-link">Pivot Vendas
                            </Link>
                            </div>
                        </li>


                        <li className="nav-item">
                            <div className="float-left">
                                <Link to={'/RH'} className="nav-link">RH
                            </Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="float-left">
                                <Link to={'/seila'} className="nav-link">Item3
                            </Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="float-left">
                                <Link to={'/ping'} className="nav-link">Ping
                            </Link>
                            </div>
                        </li>

                    </ul>

                    <span class="navbar-text">
                        <div class="btn btn-primary" onClick={() =>logout()} role="button">Sair</div>
                    </span>

                </div>
            </nav>
        )
    } else {
        return (
            ''
                )
    }
}
export default MenuNavegacao;