import React, { Component } from 'react';
import api from '../../services/api';


export default class PingToken extends Component {

    state = { _PingToken: [],_resposta:'', }
    

    async componentDidMount() {
        await this.getPingToken();
    }

    async getPingToken() {
        const response = await api.get('/ping');
        this.setState({ _PingToken: response.data });
        this.setState({ _resposta: response.status});
    }


    render() {
        if (this.state._resposta===200) {
        return (           
            <div classname="container-japa">
                <iframe
                    title="FrameJapa"
                    className="iframe-japa"
                    srcdoc={this.state._PingToken}
                    allowFullScreen
                >
                    Seu Navegador não aceita esse recurso!
         </iframe>
            </div>
        )}
        else {
            return(
                <h1>Sessão Expirada</h1>
            )
        }
    }
}
