import React, {useContext, useState} from 'react';
import auth from '../../services/auth';
import {UserContext} from '../../context';
import {history} from '../../history';

const Login = () => {
    const context = useContext(UserContext);

    const [myUser, setUser] = useState();

    const handleInputChange = ev => {
        let name = [ev.target.name];
        let value = ev.target.value;
        setUser(
            {...myUser,[name] : value}
        );
    }

    const handleSubmit = ev => {
        ev.preventDefault();
        console.log(myUser);
        auth.post('/auth', myUser)
        .then(res=>{
            localStorage.setItem('usenet-token',res.data.token);
            context.alterLogin(true);
            history.push('/');
            window.location.reload(false);            
        })
        .catch(error=>{
            alert('Usuário ou Senha Inválido')
        })
    }
    return (
        <div className="telalogin">
            <div className="row justify-content-md-center">
                <h1>Log-in</h1>
            </div>
            <div className="row justify-content-md-center">
                <p>Preencha os campos para efetuar o Login</p>
            </div>
            <div className="row justify-content-md-center">
                <br></br>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Email</label>
                                <input 
                                    className="form-control"
                                    type="text"
                                    name="EMAIL"
                                    placeholder="Email"
                                    onChange={handleInputChange}
                                    >

                                    </input>
                            </div>
                            <div className="form-group">
                                <label>Senha</label>
                                <input 
                                    className="form-control"
                                    type="password"
                                    name="PASSWORD"
                                    placeholder="Senha"
                                    onChange={handleInputChange}                                    
                                    >
                                        
                                    </input>
                            </div>
                            <button type="submit" className="btn btn-primary">Logar</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login