import React from 'react';
import {Switch, Route } from 'react-router-dom';
import Vendas from './componentes/vendas/index';
import login from './componentes/login/index';
import PrivateRoute from './privateRoutes';
import principal from './componentes/principal/index';
import pingtoken from './componentes/pingtoken/index';
import GerencialVendas from './componentes/gerencialvendas/index'

const Routes = () => (
    <Switch>
        <Route exact path='/' component={principal} />
        <Route exact path='/comercial' component={Vendas} />
        <Route exact path='/gerencialvendas' component={GerencialVendas}/>
        <Route exact path="/rh" component={()=><h1>RH</h1>}/>
        <PrivateRoute exact path="/seila" component={()=><h1>Nem Imagino</h1>}/>
        <PrivateRoute exact path="/ping"  component={pingtoken} />

        <Route exact path='/login'   component={login} />
    </Switch>
)

export default Routes;