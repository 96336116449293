import React, { Component } from 'react';
import { history } from '../../history';
export default class Principal extends Component{
    render(){

        const chamaLogin = () => 
            history.push('/login');   

        const isLogged = !!localStorage.getItem('usenet-token');      
        if (isLogged) {
        return(

            <div className="col-sm-12">
                <h1>Bem Vindo ao Sistema - Reality</h1>
            </div>
        )
        }
        else {return (
            <div className="col-sm-12">
                <h1>Sistema Reality</h1>
                <p>Este sistema é para uso interno, não são divulgados produtos e não são feitas vendas</p>
                <p>Clique no Botão abaixo para ter acesso a tela de login</p>
                <button type="button" className="btn btn-primary" onClick={() =>chamaLogin()} >Acessar Login</button>

            </div>


        )}
    }
}
